import { createApp, h, resolveComponent } from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCog,
  faSyncAlt,
  faInfoCircle,
  faAngleDown,
  faPhone,
  faEnvelope,
  faTimes,
  faArrowLeft,
  faLock,
  faUnlock,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { createRouter, createWebHistory } from "vue-router";
import { createPinia } from "pinia";
import { useI18n } from "vue-i18n";

import App from "./App.vue";
import i18n from "./i18n";
import { useLangStore } from "./stores/lang";
import { useAuthStore } from "./stores/auth";

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { createVueI18nAdapter } from "vuetify/locale/adapters/vue-i18n";

// User tracking
import VueGtag from "vue-gtag";
import Avo from "./userTracking/Avo";
import mp from "./userTracking/mixpanel";

// error monitoring
import * as Sentry from "@sentry/vue";

Avo.initAvo(
  {
    env: process.env.VUE_APP_ENVIRONMENT,
    webDebugger: false,
  },
  null,
  null,
  mp
);

if (process.env.VUE_APP_ENVIRONMENT != "prod") {
  Paddle.Environment.set("sandbox");
}
Paddle.Setup({
  vendor: parseInt(process.env.VUE_APP_PADDLE_VENDOR_ID),
  eventCallback: function (data) {
    if (data.event == "Checkout.Close") {
      window.location.reload();
    }
  },
});

i18n.setup();

var VueScrollTo = require("vue-scrollto");

library.add(
  faCog,
  faSyncAlt,
  faInfoCircle,
  faAngleDown,
  faPhone,
  faEnvelope,
  faInstagram,
  faTimes,
  faArrowLeft,
  faLock,
  faUnlock,
  faGlobe
);

function requireAuth(to, from, next) {
  const authStore = useAuthStore();

  if (authStore.authToken) {
    next();
  } else {
    // Redirect to the sign-in page or any other desired route
    next({
      path: `/${to.params.locale}/signin`,
      query: { next: to.name },
    });
  }
}

const routes = [
  {
    path: "/",
    redirect: `/${i18n.defaultLocale}`,
  },
  {
    name: "",
    path: "/:locale",
    component: {
      render() {
        return h(resolveComponent("router-view"));
      },
    },
    beforeEnter: (to, from, next) => {
      const langStore = useLangStore();
      let language = to.params.locale;
      const defaultLocale = langStore.getDefaultLocale;
      const supportedLocales = langStore.getSupportedLocales;
      if (!language) {
        language = defaultLocale;
      } else if (!Object.hasOwn(supportedLocales, language)) {
        // TODO: redirect to page not found
        language = defaultLocale;
      }

      i18n.setLocale(language);

      next();
    },
    children: [
      { name: "home", path: "", component: require("./views/Index.vue").default },
      {
        name: "socks",
        path: "/:locale/patterns/socks",
        component: require("./views/patterns/Socks.vue").default,
        beforeEnter: requireAuth,
      },
      {
        name: "mittens",
        path: "/:locale/patterns/mittens",
        component: require("./views/patterns/Mittens.vue").default,
        beforeEnter: requireAuth,
      },
      {
        name: "beanie",
        path: "/:locale/patterns/beanie",
        component: require("./views/patterns/Beanie.vue").default,
        beforeEnter: requireAuth,
      },
      // {
      //   name: "sweater",
      //   path: "/:locale/sweater",
      //   component: require("./views/SweaterRecipe.vue").default,
      //   beforeEnter: requireAuth,
      // },
      {
        name: "gaugefromswatch",
        path: "/:locale/tools/gaugefromswatch",
        component: require("./views/tools/GaugeFromSwatch.vue").default,
        beforeEnter: requireAuth,
      },
      {
        name: "knitcalculator",
        path: "/:locale/tools/knitcalculator",
        component: require("./views/tools/KnitCalculator.vue").default,
        beforeEnter: requireAuth,
      },
      {
        name: "gaugeconverter",
        path: "/:locale/tools/gaugeconverter",
        component: require("./views/tools/GaugeConverter.vue").default,
        beforeEnter: requireAuth,
      },
      {
        name: "profile",
        path: "/:locale/profile",
        component: require("./views/UserProfile.vue").default,
        beforeEnter: requireAuth,
      },
      {
        name: "subscription",
        path: "/:locale/subscription",
        component: require("./views/Subscription.vue").default,
        beforeEnter: requireAuth,
      },
      {
        name: "welcome",
        path: "/:locale/welcome",
        component: require("./views/Welcome.vue").default,
        beforeEnter: requireAuth,
      },
      { name: "signin", path: "/:locale/signin", component: require("./views/SignIn.vue").default },
      { name: "signup", path: "/:locale/signup", component: require("./views/SignUp.vue").default },
      {
        name: "verifyemail",
        path: "/:locale/verifyemail/:token",
        component: require("./views/VerifyEmail.vue").default,
      },
      {
        name: "resetpassword",
        path: "/:locale/reset-password/:code",
        component: require("./views/ResetPassword.vue").default,
      },
      {
        name: "forgotpassword",
        path: "/:locale/forgot-password",
        component: require("./views/ForgotPassword.vue").default,
      },
      {
        name: "termsandprivacy",
        path: "/:locale/termsandprivacy",
        component: require("./views/TermsAndPrivacy.vue").default,
      },
    ],
  },
  { path: "/", component: require("./views/Index.vue").default },
];

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

const pinia = createPinia();
const app = createApp({
  render: () => h(App),
});

if (process.env.VUE_APP_ENVIRONMENT != "dev") {
  Sentry.init({
    app,
    dsn: "https://d9c7f00515b1c619e9abb728f41aceb8@o4506841077841920.ingest.sentry.io/4506841080856576",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/test.knittable\.com/, /^https:\/\/knittable\.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
    },
  },
  locale: {
    adapter: createVueI18nAdapter({ i18n: i18n.vueI18n, useI18n }),
  },
});

app.use(i18n.vueI18n);
app.use(router);
app.use(VueScrollTo);
app.use(pinia);
if (process.env.VUE_APP_ENVIRONMENT == "prod") {
  app.use(VueGtag, {
    config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_TAG },
    params: {
      anonymize_ip: true,
    },
  });
}
app.use(vuetify);
app.component("font-awesome-icon", FontAwesomeIcon);
app.mount("#app");

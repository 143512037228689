export default {
  "common": {
    "Beanie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beanie"])},
    "Mittens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittens"])},
    "Socks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Socks"])},
    "Sweater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sweater"])},
    "Measurements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measurements"])},
    "Choose_a_size_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a size category"])},
    "Size_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size category"])},
    "Child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Child"])},
    "Grown_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adult"])},
    "Custom_tailored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom-tailored"])},
    "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["months"])},
    "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["years"])},
    "Calculate_pattern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculate pattern"])},
    "Knitting_gauge_from_swatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gauge from swatch"])}
  },
  "footer": {
    "Slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your ideas are knittable"])},
    "Knitting_calculations_can_be_time_consuming_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knitting calculations can be complicated and time-consuming. In Icelandic we have a saying: \"Drjúg er lykkjustundin\", which asserts that a moment spent knitting is a moment well spent. With Knittable you can minimize the preparation time and maximize the knitting time."])},
    "Supported_by_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supported by:"])}
  },
  "knitting_gauge": {
    "Knitting_gauge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knitting gauge"])},
    "Stitch_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stitch count"])},
    "Row_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Row count"])},
    "Knitting_gauge_explained_1_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knitting gauge is a combination of stitch gauge and row gauge, where the stitch gauge specifies how many stitches there are in a 10 cm wide piece of fabric, and the row gauge specifies how many rows it takes to knit a 10 cm long piece."])},
    "Knitting_gauge_explained_2_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most yarn has a suggested knitting gauge for a specific needle size. If your knitting gauge is in general average, you can try using the suggested gauge and needles."])},
    "Knitting_gauge_explained_3_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you knit tightly or loosely you can use the suggested gauge by going up or down a size, respectively, from the specified needle size. Note that for optimal results it is always safest to knit a gauge swatch before starting the project."])},
    "Stitch_gauge_explanation_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of stitches per 10 cm"])},
    "Stitch_gauge_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E.g. 18 stitches for Léttlopi"])},
    "Row_gauge_explanation_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of rows per 10 cm"])},
    "Row_gauge_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E.g. 24 rows for Léttlopi"])},
    "Estimate_row_gauge_from_stitch_gauge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimate row gauge from stitch gauge"])}
  },
  "socks": {
    "Shoe_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shoe size"])},
    "Choose_a_shoe_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a shoe size"])},
    "Ankle_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankle circumference"])},
    "Ankle_circumference_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Circumf. over the ankle (cm)"])},
    "Type_in_ankle_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type in ankle circumference"])},
    "Foot_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foot circumference"])},
    "Foot_circumference_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Circumf. over the ball of the foot (cm)"])},
    "Type_in_foot_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type in foot circumference"])},
    "Sock_height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sock height"])},
    "Sock_height_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sock height from heel to calf (cm)"])},
    "Type_in_sock_height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type in sock height"])},
    "Sock_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sock length"])},
    "Sock_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sock length from heel to toe (cm)"])},
    "Type_in_sock_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type in sock length"])},
    "Leg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leg"])},
    "Leg_paragraph_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cast on ", _interpolate(_named("castOnStitchCount")), " stitches on four double-pointed needles using the size that gives the correct gauge (", _interpolate(_named("stitchCountEachOddNeedle")), ", ", _interpolate(_named("stitchCountEachEvenNeedle")), ", ", _interpolate(_named("stitchCountEachOddNeedle")), " and ", _interpolate(_named("stitchCountEachEvenNeedle")), " stitches). Join to work in the round and knit the cuff in a 1x1 rib stitch (knit 1, purl 1) until the work measures ", _interpolate(_named("cuffLength")), " cm long."])},
    "Leg_paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Knit ", _interpolate(_named("anklePieceLength")), " cm in stockinette stitch."])},
    "Heel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heel"])},
    "Heel_paragraph_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Now work the heel flap. Knit the stitches on the 1st needle, stop, turn your work around, and slip the first stitch purlwise. Purl in this direction until you have purled all the stitches from the 4th needle onto the 1st needle as well. You should now have ", _interpolate(_named("castOnStitchCount_half")), " stitches on your 1st needle, which form the beginning of the heel flap. Continue back and forth in stockinette (slip the first stitch knitwise and knit on the right side, slip the first stitch purwise and purl on the wrong side) until you have worked ", _interpolate(_named("heelFlapRowCount")), " rows of the heel flap, when counting on the right half where the 4th needle used to be. Your next row should start on the right side."])},
    "Heel_next_up_heel_decreases_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now the heel is formed with decreases on both sides:"])},
    "Heel_first_decrease_row_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1st row of heel decreases:"])},
    "Heel_first_decrease_row_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Slip the first stitch knitwise, knit until ", _interpolate(_named("heelFlapMarginStitchCountPlusOne")), " stitches remain. Slip 1 knitwise, knit 1 stitch, pass the slipped stitch over the knitted one and turn the work around."])},
    "Heel_second_row_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2nd row:"])},
    "Heel_second_row_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Slip the first stitch purlwise, purl until ", _interpolate(_named("heelFlapMarginStitchCountPlusOne")), " stitches remain, purl 2 together and turn the work around."])},
    "Heel_paragraph_2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Repeat the 1st and 2nd row, but with ", _interpolate(_named("heelFlapMarginStitchCount")), ", ", _interpolate(_named("heelFlapMarginStitchCountMinusOne")), ", ", _interpolate(_named("heelFlapMarginStitchCountMinusTwo")), ", etc. heel flap stitches remaining, until all side stitches have been knit into the heel."])},
    "Foot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foot"])},
    "Foot_paragraph_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The rest of the foot is knit in the round. Divide the heel flap stitches back onto two needles and pick up all ", _interpolate(_named("heelFlapEdgeStitchCount")), " edge stitches on each side of the heel flap using those needles. Add 1 more stitch onto each needle by picking up a stitch at the start of the heel flap (originating from the last round before the heel flap). The beginning of the round is now between these two needles."])},
    "Knit_the_first_two_rounds_the_following_way_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knit the first 2 rounds as follows:"])},
    "Knit_a_round_with_twisted_edge_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knit one round, being sure to pick up and knit the edge stitches through the back loop"])},
    "Knit_a_round_with_decreases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knit the stitches on the 1st needle until 3 stitches remain, knit 2 together, knit the last stitch. Knit the stitches from the 2nd and 3rd needle, knit 1 stitch of the 4th needle, slip 1 knitwise, knit 1 stitch and pass the slipped stitch over the knitted one. Knit the rest of the stitches."])},
    "Repeat_the_two_rounds_above_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Repeat the 2nd and 3rd round until you have a total of ", _interpolate(_named("footStitchCount")), " stitches."])},
    "Knit_in_the_round_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Knit in the round until the sock reaches ", _interpolate(_named("footLengthMinusTipLength")), " cm when measured from the heel."])},
    "Toe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toe"])},
    "Make_sure_equal_number_of_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure the combined number of stitches on the 1st and 4th needle (sole) equals the combined number of stitches on the 2nd and 3rd needle (instep) before you start the toe decreases."])},
    "Work_to_decreases_in_the_following_way_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work the toe decreases as follows:"])},
    "First_round_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1st round (decrease round):"])},
    "Decrease_round_description_first_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("firstNeedle")), " Knit until ", _interpolate(_named("tipMarginStitchCountPlusTwo")), " stitches remain on the needle.  Knit 2 together", _interpolate(_named("conditionalAdditionalLastStitch")), "."])},
    "_and_then_knit_x_stitches": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([", knit ", _interpolate(_named("tipMarginStitchCount"))])},
    "Decrease_round_description_second_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("secondNeedle")), " ", _interpolate(_named("conditionalAdditionalFirstStitch")), "Slip 1 knitwise, knit 1 stitch and pass the slipped stitch over the knitted one. Knit the rest of the stitches."])},
    "Knit_one_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knit 1. "])},
    "Decrease_round_description_third_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("thirdNeedle")), " Same as the 1st needle."])},
    "Decrease_round_description_fourth_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("fourthNeedle")), " Same as the 2nd needle."])},
    "Knit_decrease_rounds_from_here_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Continue to knit decrease rounds until ", _interpolate(_named("finalRowStitchCount")), " stitches remain. Break the yarn and pull it through the stitches. Weave in all ends."])},
    "Knit_the_other_sock_the_same_way_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knit the other sock the same way."])},
    "v1_1_0": {
      "Leg_paragraph_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cast on ", _interpolate(_named("castOnStitchCount")), " stitches on four double-pointed needles (", _interpolate(_named("stitchCountEachOddNeedle")), ", ", _interpolate(_named("stitchCountEachEvenNeedle")), ", ", _interpolate(_named("stitchCountEachOddNeedle")), " and ", _interpolate(_named("stitchCountEachEvenNeedle")), " stitches) or a circular needle. Join to work in the round and knit the cuff in a 1x1 rib stitch (knit 1, purl 1) until the work measures ", _interpolate(_named("cuffLength")), " cm long."])},
      "Heel_paragraph_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Now work the heel flap. Knit ", _interpolate(_named("heelflapStitchCountLeft")), " stitches on the 1st needle, stop, turn your work around, and slip the first stitch purlwise. Purl the remaining ", _interpolate(_named("heelflapStitchCountLeftMinusOne")), " stitches from the 1st needle and place a stitch marker. Then purl ", _interpolate(_named("heelflapStitchCountRight")), " stitches from the 4th needle onto the 1st needle as well. You should now have ", _interpolate(_named("heelflapStitchCount")), " stitches on your 1st needle, which form the beginning of the heel flap. The other ", _interpolate(_named("instepStitchCount")), " stitches will form the instep."])},
      "Heel_paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Continue back and forth over these ", _interpolate(_named("heelflapStitchCount")), " stitches in stockinette (knit on the right side, purl on the wrong side, always slipping the first stitch) until you have worked ", _interpolate(_named("heelFlapRowCount")), " rows of the heel flap, when counting to the right of the stitch marker. There should now be ", _interpolate(_named("heelFlapEdgeStitchCount")), " edge stitches on each side of the heel flap and your next row should start on the right side."])},
      "Heel_first_decrease_row_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Slip the first stitch knitwise, knit until stitch marker. Knit ", _interpolate(_named("heelFlapCenterLeftMinusOne")), " stitch(es). Slip 1 knitwise, knit 1 stitch, pass the slipped stitch over the knitted one and turn the work around."])},
      "Heel_second_row_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Slip the first stitch purlwise, purl until stitch marker. Purl ", _interpolate(_named("heelFlapCenterRightMinusOne")), " stitch(es). Purl 2 together and turn the work around."])},
      "Heel_paragraph_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat the 1st and 2nd row until all side stitches have been knit into the heel."])},
      "Heel_paragraph_3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Slip the first stitch knitwise, knit ", _interpolate(_named("heelFlapCenterRightMinusOne")), " stitch(es) to reach the stitch marker."])},
      "Foot_paragraph_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The rest of the foot is knit in the round. Divide the heel flap stitches back onto two needles by the stitch marker and pick up all ", _interpolate(_named("heelFlapEdgeStitchCount")), " edge stitches on each side of the heel flap using those needles. Add 1 more stitch onto each needle by picking up a stitch at the start of the heel flap (originating from the last round before the heel flap). The beginning of the round is still between these two needles."])},
      "Foot_paragraph_1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Now there should be ", _interpolate(_named("totalStitchCountBeforeHeelDecrease")), " stitches in total on your needles (", _interpolate(_named("firstNeedleStitchCount")), ", ", _interpolate(_named("secondNeedleStitchCount")), ", ", _interpolate(_named("thirdNeedleStitchCount")), " and ", _interpolate(_named("fourthNeedleStitchCount")), " stitches). If using a circular needle, put stitch markers where the 1st needle would meet the 2nd, and where the 3rd would meet the 4th."])},
      "Knit_a_round_with_decreases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knit the stitches on the 1st needle until 2 stitches remain, knit 2 together. Knit the stitches from the 2nd and 3rd needle. Slip 1 knitwise of the 4th needle, knit 1 stitch and pass the slipped stitch over the knitted one. Knit the rest of the stitches."])},
      "Repeat_the_two_rounds_above_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Repeat the 2nd and 3rd round ", _interpolate(_named("heelDecreaseCountMinusOne")), " more times until you have a total of ", _interpolate(_named("footStitchCount")), " stitches."])},
      "Make_sure_equal_number_of_stitches": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Make sure the stitches are equally distributed on the needles, or ", _interpolate(_named("stitchCountEachOddNeedle")), ", ", _interpolate(_named("stitchCountEachEvenNeedle")), ", ", _interpolate(_named("stitchCountEachOddNeedle")), " and ", _interpolate(_named("stitchCountEachEvenNeedle")), " stitches, before you start the toe decreases. If using a circular needle, make sure the stitch markers are where the 1st needle would meet the 2nd, and where the 3rd would meet the 4th."])}
    }
  },
  "common2": {
    "First_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1st round:"])},
    "Second_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2nd round:"])},
    "Second_and_third_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2nd and 3rd round:"])},
    "Second_to_fourth_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2nd to 4th round:"])},
    "Third_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3rd round:"])},
    "Fourth_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4th round:"])},
    "Fifth_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5th round:"])},
    "Sixth_and_seventh_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6th and 7th round:"])},
    "Eighth_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8th round:"])},
    "Ninth_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9th round:"])},
    "First_needle_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1st needle:"])},
    "Second_needle_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2nd needle:"])},
    "Third_needle_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3rd needle:"])},
    "Fourth_needle_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4th needle:"])},
    "Knit_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knit."])},
    "Knit_a_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knit a decrease round."])}
  },
  "mittens": {
    "Cuff_circumference_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuff circumference"])},
    "Cuff_circumference_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Circumference around the wrist (cm)"])},
    "Type_in_cuff_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type in cuff circumference"])},
    "Cuff_length_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuff length"])},
    "Cuff_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuff length (cm)"])},
    "Type_in_cuff_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type in cuff length"])},
    "Hand_circumference_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hand circumference"])},
    "Hand_circumference_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hand circumference (cm)"])},
    "Type_in_hand_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type in hand circumference"])},
    "Hand_length_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hand length"])},
    "Hand_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hand length from wrist to fingertip (cm)"])},
    "Type_in_hand_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type in hand length"])},
    "Felting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felting"])},
    "Felting_information_bubble_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When mittens are knitted with the intention to felt them, they are knitted to a larger size than the desired final size, and then felted by hand or in a washing machine until they have shrunk to the desired size."])},
    "Felting_information_bubble_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After this treatment the wool becomes denser, increasing their durability, water resistance, and overall warmth."])},
    "Felting_density_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felting density"])},
    "Choose_how_much_to_felt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose how much to felt"])},
    "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No felting"])},
    "Felt_a_little": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A little (mittens are knitted 10% larger)"])},
    "Felt_somewhat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Somewhat (mittens are knitted 20% larger)"])},
    "Felt_a_lot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A lot (mittens are knitted 30% larger)"])},
    "Yarn_for_felting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yarn for felting"])},
    "Yarn_for_felting_info_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All non-superwash wool yarns felt, but the end result differs between yarn types. Knitting gauge is also a factor, where loose knitting felts more than tight knitting."])},
    "Yarn_for_felting_info_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The choices \"A little\", \"Somewhat\" and \"A lot\" are therefore estimates for your final product, as a more precise measurement would vary depending on the yarn and knitting style."])},
    "Suggestion_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggestion:"])},
    "Yarn_for_felting_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double plötulopi knitted with stitch gauge 16 st/10 cm."])},
    "Cuff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuff"])},
    "Cuff_felting_side_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rib stitch shortens more than stockinette stitch when felted, so the cuff length has been increased by a higher ratio than the rest of the measurements."])},
    "Cuff_cast_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cast on ", _interpolate(_named("castOnStitchCount")), " stitches on four double-pointed needles using the size that gives the correct gauge. Join to work in the round and knit the cuff in a 1x1 rib stitch (knit 1, purl 1) until the work measures ", _interpolate(_named("calculatedCuffLength")), " cm long."])},
    "Hand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hand"])},
    "Knit_a_round_and_increase": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Knit a round of stockinette stitch while evenly adding ", _interpolate(_named("handCuffStitchCountDiff")), " stitches throughout the round. There should now be ", _interpolate(_named("handStitchCount")), " stitches on your needles."])},
    "Knit_in_the_round_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Knit in the round until the mitten reaches ", _interpolate(_named("beforeThumbLength")), " cm when measured from the cuff."])},
    "Make_sure_half_the_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure half the stitches are on the 1st and 2nd needle, and the other half on the 3rd and 4th needle before continuing."])},
    "Right_mitten_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right mitten:"])},
    "Right_mitten_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Knit all stitches on the 1st and 2nd needle, and ", _interpolate(_named("thumbMarginStitchCount")), " stitches from the 3rd needle. Knit ", _interpolate(_named("thumbOpeningStitchCount")), " stitches using waste yarn. Move the waste yarn stitches back onto the left needle and knit them with the base yarn. Knit the rest of the round."])},
    "Left_mitten_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left mitten:"])},
    "Left_mitten_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Knit all stitches on the 1st needle and keep knitting until ", _interpolate(_named("thumbMarginPlusThumbOpeningStitchCount")), " stitches remain on the 2nd needle. Knit ", _interpolate(_named("thumbOpeningStitchCount")), " stitches using waste yarn. Move the waste yarn stitches back onto the left needle and knit them with the base yarn. Knit the rest of the round."])},
    "Decrease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decrease"])},
    "Make_sure_again_half_the_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Again, make sure that half of your stitches are on the 1st and 2nd needle, and the other half are on the 3rd and 4th."])},
    "Work_to_decreases_in_the_following_way_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work the tip decreases as follows:"])},
    "First_round_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1st round (decrease round):"])},
    "Knit_one_stitch_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knit 1 stitch."])},
    "Decrease_round_description_first_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("firstNeedle")), " ", _interpolate(_named("conditionalAdditionalFirstStitch")), " Slip 1 knitwise, knit 1 stitch and pass the slipped stitch over the knitted one. Knit the rest of the stitches."])},
    "Decrease_round_description_second_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("secondNeedle")), " Knit until ", _interpolate(_named("tipMarginStitchCountPlusTwo")), " stitches remain on the needle.  Knit 2 together", _interpolate(_named("conditionalAdditionalLastStitch")), "."])},
    "_and_then_knit_x_stitches": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([", knit ", _interpolate(_named("tipMarginStitchCount"))])},
    "Decrease_round_description_third_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("thirdNeedle")), " Same as the 1st needle."])},
    "Decrease_round_description_fourth_needle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("fourthNeedle")), " Same as the 2nd needle."])},
    "Knit_decrease_rounds_from_here_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Now knit decrease rounds in every round until ", _interpolate(_named("finalRowStitchCount")), " stitches remain. Break the yarn and pull it through the stitches."])},
    "Thumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thumb"])},
    "Thumb_description_0": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Unravel the waste yarn to create the thumb opening. Pick up ", _interpolate(_named("thumbOpeningStitchCountMinusOne")), " stitches from the upper half of the opening and ", _interpolate(_named("thumbOpeningStitchCount")), " from the lower half. Additionally, pick up 2 stitches on either side of the opening. There should now be ", _interpolate(_named("initialThumbStitchCount")), " stitches on your needles: ", _interpolate(_named("initialThumbStitchCountHalfFloor")), " for the upper half of the thumb and ", _interpolate(_named("initialThumbStitchCountHalfCeil")), " for the lower half. Divide each half onto two needles if using double-pointed needles, or split the stitches in between the two halves if using the magic loop method."])},
    "Thumb_first_round_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start the round at the palm-facing side of the thumb opening. Knit the stitches picked up from the sides through the back loop."])},
    "Thumb_second_round_description_side_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note that the round starts with the upper half of the thumb on the right mitten, but the lower half on the left mitten."])},
    "Thumb_second_round_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Decrease the number of stitches by ", _interpolate(_named("stitchesKnitTogetherInSecondRow")), " by knitting the lower half of the thumb as follows: Knit 2 together, knit until the last 2 stitches of the lower half of the thumb, slip 1 knitwise, knit 1 stitch and pass the slipped stitch over the knitted one. Knit the upper half of the thumb without decreases. There should now be ", _interpolate(_named("thumbStitchCount")), " stitches on your needles."])},
    "Thumb_knit_in_the_round_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Knit in the round until the thumb reaches ", _interpolate(_named("thumbLengthMinusThumbTiplength")), " cm."])},
    "Thumb_knit_decrease_rounds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Knit decrease rounds as follows until ", _interpolate(_named("thumbFinalRowStitchCount")), " stitches remain on your needles."])},
    "Thumb_decrease_round_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work each half of the thumb the same way: Slip 1 knitwise, knit 1 stitch and pass the slipped stitch over the knitted one, knit until 2 stitches remain on your needles, knit 2 together."])},
    "Thumb_break_the_yarn_and_pull": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Break the yarn and pull it through the stitches."])},
    "Weave_in_all_ends_and_knit_the_other_mitten": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weave in all ends. Knit the other mitten the same way, but follow the instructions labelled \"Left mitten\" if the right one was knitted previously."])},
    "Felting_description_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The mittens can be felted by hand or by using a washing machine. Felting by hand offers more control in terms of form and size, whereas felting in a washing machine can vary depending on the machine and the programmes used. In most cases this is harmless since the mittens can be stretched while still wet if they come out too small, or felted again (even at a higher temperature) if they are still too big."])},
    "Felting_description_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In general, higher temperature, the use of wool soap/laundry detergent, or washing with other garments are all factors that increase felting. If washing with other clothes, make sure the clothes do not produce lint."])},
    "Felting_a_little_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Put the mittens in a washing machine using a short program (not a wool program) with a spin cycle and temperature of 40°. Use a small amount of wool soap/laundry detergent."])},
    "Felting_somewhat_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Put the mittens in a washing machine using a full regular program (not a wool program) with a spin cycle and temperature of 40°. Use a small amount of wool soap/laundry detergent."])},
    "Felting_a_lot_suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Put the mittens in a washing machine using a full regular program (not a wool program) with a spin cycle and temperature of 60°. Use a small amount of wool soap/laundry detergent."])},
    "v1_1_0": {
      "Cuff_cast_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cast on ", _interpolate(_named("castOnStitchCount")), " stitches on four double-pointed needles (", _interpolate(_named("stitchCountEachOddNeedle")), ", ", _interpolate(_named("stitchCountEachEvenNeedle")), ", ", _interpolate(_named("stitchCountEachOddNeedle")), " and ", _interpolate(_named("stitchCountEachEvenNeedle")), " stitches) or a circular needle. Join to work in the round and knit the cuff in a 1x1 rib stitch (knit 1, purl 1) until the work measures ", _interpolate(_named("calculatedCuffLength")), " cm long."])},
      "Make_sure_half_the_stitches": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Make sure the stitches are equally distributed on the needles, or ", _interpolate(_named("stitchCountEachOddNeedle")), ", ", _interpolate(_named("stitchCountEachEvenNeedle")), ", ", _interpolate(_named("stitchCountEachOddNeedle")), " and ", _interpolate(_named("stitchCountEachEvenNeedle")), " stitches, before continuing. If using a circular needle, place a stitch marker where the 2nd needle would meet the 3rd (after ", _interpolate(_named("halfHandStitchCount")), " stitches)."])},
      "Right_mitten_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Knit ", _interpolate(_named("halfHandStitchCount")), " stitches on the 1st and 2nd needle (or to stitch marker) and ", _interpolate(_named("thumbMarginStitchCount")), " stitch(es) from the 3rd needle. Knit ", _interpolate(_named("thumbOpeningStitchCount")), " stitches using waste yarn. Move the waste yarn stitches back onto the left needle and knit them with the base yarn. Knit the rest of the round."])},
      "Left_mitten_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Knit ", _interpolate(_named("stitchCountUntilThumb")), " stitches. Knit ", _interpolate(_named("thumbOpeningStitchCount")), " stitches using waste yarn. Move the waste yarn stitches back onto the left needle and knit them with the base yarn, as well as the ", _interpolate(_named("thumbMarginStitchCount")), " remaining stitch(es) on the 2nd needle (or until stitch marker). Knit the rest of the round."])},
      "Make_sure_again_half_the_stitches": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Again, make sure the stitches are equally distributed on the needles, or ", _interpolate(_named("stitchCountEachOddNeedle")), ", ", _interpolate(_named("stitchCountEachEvenNeedle")), ", ", _interpolate(_named("stitchCountEachOddNeedle")), " and ", _interpolate(_named("stitchCountEachEvenNeedle")), " stitches, before continuing. If using a circular needle, make sure the stitch marker is still where the 2nd needle would meet the 3rd (after ", _interpolate(_named("halfHandStitchCount")), " stitches)."])},
      "Thumb_first_round_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Start the round at the palm-facing side of the thumb opening and knit all ", _interpolate(_named("thumbInitialHalfStitchCount")), " stitches of the first half while making sure to knit the stitches picked up from the sides through the back loop. Knit the second half in a similar manner."])},
      "Thumb_second_round_right_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Knit all ", _interpolate(_named("thumbInitialUpperHalfStitchCount")), " stitches of the first half. Knit 2 together. Knit ", _interpolate(_named("thumbInitialLowerHalfStitchCountMinusFour")), " stitches leaving 2 stitches of the second half, slip 1 knitwise, knit 1 stitch and pass the slipped stitch over the knitted one."])},
      "Thumb_second_round_left_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Knit 2 together. Knit ", _interpolate(_named("thumbInitialLowerHalfStitchCountMinusFour")), " stitches, leaving 2 stitches of the first half, slip 1 knitwise, knit 1 stitch and pass the slipped stitch over the knitted one. Knit all ", _interpolate(_named("thumbInitialUpperHalfStitchCount")), " stitches of the second half to finish the round."])},
      "Thumb_knit_in_the_round_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["There should now be ", _interpolate(_named("thumbStitchCount")), " on your needles. Knit in the round until the thumb reaches ", _interpolate(_named("thumbLengthMinusThumbTiplength")), " cm."])},
      "Thumb_knit_decrease_rounds": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Now knit decrease rounds as follows in every round  until ", _interpolate(_named("thumbFinalRowStitchCount")), " stitches remain on your needles."])},
      "Thumb_decrease_round_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slip 1 knitwise, knit 1 stitch and pass the slipped stitch over the knitted one, knit until 2 stitches remain of the first half, knit 2 together. Slip 1 knitwise, knit 1 stitch and pass the slipped stitch over the knitted one, knit until 2 stitches remain of the second half, knit 2 together."])}
    }
  },
  "common3": {
    "Size_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
    "Choose_a_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a size"])},
    "Print_pattern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print pattern"])},
    "This_browser_cannot_print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately this browser cannot be used to print the pattern. Try opening the page in e.g. Chrome."])},
    "Decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decrease round:"])}
  },
  "beanie": {
    "Head_circumference_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Head circumf."])},
    "Head_circumference_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Head circumference over the forehead and the widest part of the back of the head (cm)"])},
    "Type_in_head_circumference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type in head circumference"])},
    "Brim_height_in_cm_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brim height"])},
    "Brim_height_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height of the brim folded up at the bottom of the beanie (cm)"])},
    "Type_in_brim_height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type in the height of the brim"])},
    "There_are_too_few_cast_on_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are too few cast on stitches in this beanie. Increase the stitch gauge or choose a larger size."])},
    "Body": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Body"])},
    "Ribbing_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ribbing type"])},
    "Single_rib_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single (knit 1, purl 1)"])},
    "Double_rib_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double (knit 2, purl 2)"])},
    "Ribbing_coverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ribbing coverage"])},
    "Whole_beanie_ribbed_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The whole beanie"])},
    "Only_brim_ribbed_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just the brim"])},
    "Decrease_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decrease type"])},
    "Even_decrease_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invisible decrease"])},
    "Cross_decrease_option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross decrease"])},
    "Body_cast_on": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cast on ", _interpolate(_named("castOnStitchCount")), " stitches on a circular needle using the size that gives the correct gauge."])},
    "Body_cast_on_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A tubular cast on will give a nice edge for the brim, but a long tail cast on works well too."])},
    "body_description_ribbed_start": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Join to work in the round and knit the cuff in a rib stitch (knit ", _interpolate(_named("cuffPatternRepCountHalf")), ", purl ", _interpolate(_named("cuffPatternRepCountHalf")), ") until the work measures ", _interpolate(_named("straightCuffLength")), " cm long."])},
    "body_description_stockinette_top_zero_fold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Knit ", _interpolate(_named("lengthBeforeDecreaseMinusStraightCuffLength")), " cm in stockinette stitch."])},
    "body_description_stockinette_top_nonzero_fold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Knit ", _interpolate(_named("lengthBeforeDecreaseMinusStraightCuffLength")), " cm in stockinette stitch until the work measures ", _interpolate(_named("lengthBeforeDecrease")), " cm, or ", _interpolate(_named("lengthBeforeDecreaseMinusFoldLength")), " cm if the body is measured with a folded brim of ", _interpolate(_named("foldLength")), " cm. The latter measurement is more precise, especially when knitting with heavier yarn, since part of the beanie length goes into the fold itself."])},
    "body_description_whole_ribbed_nonzero_fold": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Join to work in the round and knit the cuff in a rib stitch (knit ", _interpolate(_named("cuffPatternRepCountHalf")), ", purl ", _interpolate(_named("cuffPatternRepCountHalf")), ") until the work measures ", _interpolate(_named("straightCuffLength")), " cm long, or ", _interpolate(_named("straightCuffLengthMinusFoldLength")), " cm if the body is measured with a folded brim of ", _interpolate(_named("foldLength")), " cm. The latter measurement is more precise, especially when knitting with heavier yarn, since part of the beanie length goes into the fold itself."])},
    "Decrease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decrease"])},
    "First_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1st decrease round:"])},
    "First_even_decrease_round_description_2x2_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Knit 2, purl 2 together.**"])},
    "First_even_decrease_round_description_1x1_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Slip 1 knitwise, knit 1 stitch and pass the slipped stitch over the knitted one. Knit 1 purl 1.**"])},
    "First_even_decrease_round_description_stockinette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Knit 2, knit 2 together.**"])},
    "Repeat_from_star_to_2star_": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Knit the whole round repeating from * to **. There should now be  ", _interpolate(_named("castOnStitchCountMinusEvenlyDecreasedStitchesCount")), " stitches on your needles."])},
    "Knit_x_rounds_without_decreases": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Knit ", _interpolate(_named("numberOfRowsBetweenDecreases")), " rounds without decreases."])},
    "Second_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2nd decrease round:"])},
    "Second_even_decrease_round_description_2x2_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Knit 2 together, purl 1.**"])},
    "Second_even_decrease_round_description_1x1_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Knit 2 together, purl 1.**"])},
    "Second_even_decrease_round_description_stockinette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Knit 2 together, knit 1.**"])},
    "Third_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3rd decrease round:"])},
    "Third_even_decrease_round_description_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Slip 1 knitwise, knit 1 stitch and pass the slipped stitch over the knitted one.**"])},
    "Third_even_decrease_round_description_stockinette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Knit 2 together.**"])},
    "Final_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final decrease round:"])},
    "Final_even_decrease_round_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Knit 2 together.**"])},
    "Repeat_from_star_to_2star_with_side_note": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Knit the whole round repeating from * to **. There should now be  ", _interpolate(_named("castOnStitchCountMinusEvenlyDecreasedStitchesCount")), " stitches on your needles", _interpolate(_named("conditionalLastStitchSideNote")), "."])},
    "the_last_stitch_is_single": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" (the last stitch is knitted single)"])},
    "Break_the_yarn_and_pull_weave_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Break the yarn and pull it through the stitches. Weave in all ends."])},
    "Cross_decrease_intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["During a decrease round 2 stitches are removed in 4 places in the round (8 decreases in total). Place a stitch marker to mark the place for each pair of decreases."])},
    "First_round_decrease_round_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1st round (decrease round):"])},
    "Back_of_the_head_left_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back of the head, left:"])},
    "Work_x_stitches_in_x_stitch_": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Work ", _interpolate(_named("numberOfStitchesBetweenDecreases")), " stitches in ", _interpolate(_named("typeOfStitch")), " stitch."])},
    "stockinette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stockinette"])},
    "rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rib"])},
    "Decrease_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decrease:"])},
    "First_cross_decrease_round_description_stockinette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slip 1 knitwise, knit 1 stitch and pass the slipped stitch over the knitted one. Knit 2 together."])},
    "First_cross_decrease_round_description_rib": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Knit 2 together", _interpolate(_named("IntermediateRibStitches")), ". Slip 1 knitwise, knit 1 stitch and pass the slipped stitch over the knitted one."])},
    "purl_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", purl 1"])},
    "work_x_stitches_in_rib_stitch": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([", work ", _interpolate(_named("crossDecreaseColumnStitchesMinusTwo")), " stitches in rib stitch"])},
    "Left_side_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Left side:"])},
    "Work_x_stitches_in_x_stitch_and_then_decrease": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Work ", _interpolate(_named("numberOfStitchesBetweenDecreases")), " stitches in ", _interpolate(_named("typeOfStitch")), " stitch and then perform a decrease."])},
    "Forehead_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forehead:"])},
    "Right_side_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right side:"])},
    "Back_of_the_head_right_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back of the head, right:"])},
    "Now_the_number_of_stitches_has_decreased_": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The number of stitches has decreased by 8, leaving  a total of ", _interpolate(_named("castOnStitchCountMinusEight")), "."])},
    "Knit_the_round_in_x_without_decreases": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Knit the round in ", _interpolate(_named("typeOfStitch")), " stitch without decreases."])},
    "Knit_a_decrease_round_except_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knit a decrease round where the decreases are worked in the same place as in the 1st round (except now there are 2 fewer stitches between the decreases)."])},
    "Repeat_the_last_two_rounds_x_times_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Repeat the last two rounds ", _interpolate(_named("everyOtherRowDecreaseCountMinusOne")), " more time(s), until ", _interpolate(_named("stitchCountBeforeEveryRowDecreases")), " stitches remain on your needles."])},
    "Now_there_should_be_x_stitches_remaining": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Now there should be ", _interpolate(_named("stitchCountBeforeEveryRowDecreases")), " stitches remaining on your needles."])},
    "Work_a_decrease_in_every_round_until_": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Now work a decrease round in every round until ", _interpolate(_named("crossDecreaseFinalRowStitchCount")), " stitches remain on your needles. Break the yarn and pull it through the stitches. Weave in all ends."])}
  },
  "common4": {
    "Design": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design"])}
  },
  "navbar": {
    "Patterns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patterns"])},
    "Tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools"])},
    "Sign_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
    "Sign_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out"])},
    "Subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe"])}
  },
  "common_user_settings": {
    "First_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "Last_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "Password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "New_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "Confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
    "E_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])}
  },
  "sign_in": {
    "Not_a_registered_user_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not a registered user?"])},
    "Sign_up_instead_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up instead."])},
    "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "Welcome_back_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome back!"])},
    "Forgot_password_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
    "Get_a_new_one_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get a new one."])}
  },
  "sign_up": {
    "Already_a_registered_user_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already a registered user?"])},
    "Sign_in_instead_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in instead."])},
    "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "Suceess_Your_account_has_been_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success! Your account has been created."])},
    "Your_free_trial_starts_now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your 14 day free trial has started"])},
    "We_ve_sent_a_verification_email_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We've sent a verification email to your provided address. Please check your inbox and click the link to activate your account."])},
    "Need_help_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need help?"])},
    "Contact_our_support_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact our support team."])},
    "Create_a_user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a user"])},
    "Knittable_can_send_me_marketing_emails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I would like to receive knitting inspirations and be up to date on latest knitting designs and tools"])},
    "I_accept_terms_and_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I accept Knittable's Terms and Conditions"])}
  },
  "verify_email": {
    "Verify_my_email_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify my email address"])},
    "Your_email_address_has_been_verified_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email address has been verified."])},
    "Sign_in_to_continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in to continue."])},
    "Click_here_to_resend_verification_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend verification email"])},
    "Verification_email_has_been_resent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification email has been resent"])}
  },
  "user_profile": {
    "Reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
    "Edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit profile"])},
    "Delete_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete account"])},
    "Are_you_sure_you_want_to_delete_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete your account?"])},
    "This_action_cannot_be_undone_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This action cannot be undone."])},
    "Save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "User_Profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Profile"])},
    "Delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "Reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset"])}
  },
  "common5": {
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])}
  },
  "get_started": {
    "Your_knitting_design_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your knitting helper"])},
    "Unleash_your_creativity_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unleash your creativity and "])},
    "Unleash_your_creativity_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["knit "])},
    "Unleash_your_creativity_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your aspirations"])},
    "Knittable_is_your_interactive_tool_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knittable is your interactive tool for the perfect outcome using any yarn. All you need is your knitting gauge, size preference and Knittable creates the perfect pattern."])},
    "Try_it_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try it out"])}
  },
  "how_does_it_work": {
    "How_does_it_work_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How does it work?"])},
    "1_step_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your yarn"])},
    "2_step_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select size"])},
    "3_step_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start knitting"])},
    "1_step_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knittable works for any yarn type. All you have to do is put in the knitting gauge."])},
    "2_step_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select from a range of sizes or customize your own."])},
    "3_step_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick up your needles and start knitting!"])},
    "1_step_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecting the proper knitting gauge is crucial for achieving the desired fit and drape of your finished garment."])},
    "2_step_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The measurements are used to calculate the proportions of the knitted garment taking felting and positive or negative ease into account."])},
    "3_step_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bring the joy through the meditative rhythm of the needles and the creativity of transforming yarn into a beautiful piece of art."])}
  },
  "pricing": {
    "Free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free"])},
    "Monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
    "Yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yearly"])},
    "yearly_discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17% discount"])},
    "paid_yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per month - $55 paid yearly"])},
    "Choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change to this plan"])},
    "Chosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "Next_payment_due_by": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Next payment due by ", _interpolate(_named("nextBillDate"))])},
    "Cancellation_effective_from": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cancellation takes effect after ", _interpolate(_named("cancellationEffectiveDate"))])},
    "Update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "Cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "Payment_is_past_due_next_retry_at": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Payment is past due, next retry at ", _interpolate(_named("nextRetryDate"))])},
    "Subscription_expired_since": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Subscription expired since ", _interpolate(_named("nextBillDate"))])},
    "Choose_what_fits_you_best": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose what fits you best"])},
    "Knitting_tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knitting tools"])},
    "Pattern_add_on_for_tools": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pattern component for tools"])},
    "Interactive_knitting_patterns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interactive knitting patterns"])}
  },
  "our_patterns": {
    "Our_Patterns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Patterns"])},
    "Our_patterns_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knittable provides interactive knitting patterns that can be adapted to your design idea"])},
    "Our_patterns_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The patterns suit any yarn type and any size, including both children and adults . These simple patterns are ideal for beginners looking for a basic knit, or experienced knitters wanting a framework on which to build their own designs."])}
  },
  "subscription": {
    "Change_or_cancel_at_any_time_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can change or cancel at any time in your user profile settings"])},
    "Go_back_to_pattern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back"])}
  },
  "gauge_from_swatch": {
    "tool_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This tool determines your knitting gauge based on the size of your gauge swatch and the stitch and row count within it."])},
    "Swatch_dimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swatch dimensions"])},
    "Height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length"])},
    "Width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Width"])},
    "Height_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measured length of the swatch (cm)"])},
    "Width_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measured width of the swatch (cm)"])},
    "Number_of_stitches_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of stitches across the measured width"])},
    "Number_of_rows_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of rows across the measured length"])},
    "Height_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type in the length"])},
    "Width_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type in the width"])},
    "Swatch_dimensions_information_bubble_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Measure the width/length of your swatch, then count the stitches/rows accordingly."])},
    "Swatch_dimensions_information_bubble_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The swatch does not have to be 10 x 10 cm, but a larger swatch will provide a more accurate gauge."])},
    "Your_knitting_gauge_is_": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your knitting gauge is ", _interpolate(_named("stitchGauge")), " stitches x ", _interpolate(_named("rowGauge")), " rows = 10 x 10 cm."])}
  },
  "common6": {
    "Calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculate"])}
  },
  "welcome": {
    "Welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to knittable"])},
    "You_have_x_left_of_your_free_trial": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have ", _interpolate(_named("remainingDays")), " day(s) left of your free trial"])},
    "View_subscription_plans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View subscription plans"])},
    "You_have_access_via_partnership_program_until": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have full access to Knittable via our Partnership Program until ", _interpolate(_named("expiresAt")), "."])},
    "You_have_access_via_partnership_program": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have full access to Knittable via our Partnership Program."])},
    "Your_customers_can_use_the_code": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Your customers can use the code ", _interpolate(_named("code")), " to get a 15% discount of the first year in a yearly subscription."])}
  },
  "tracking_behavior": {
    "We_would_like_to_track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We use cookies and similar technologies to collect anonymus user data, for analytics purposes and to enhance user satisfaction. Is this ok?"])},
    "Yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
  },
  "pattern_form": {
    "A_new_version_is_available_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A new version of this pattern is available. Finish your ongoing project and upgrade to the new version for your next one."])},
    "Upgrade_and_calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade and calculate pattern"])}
  },
  "common7": {
    "Knit_calculator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knit calculator"])},
    "Subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe"])},
    "Subscribe_to_enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe to enable"])},
    "Change_gauge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gauge converter"])}
  },
  "knit_calculator": {
    "tool_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This tool utilises your knitting gauge to calculate how many stitches or rows you need to knit a piece of a desired width or length."])},
    "stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stitches"])},
    "rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rows"])},
    "Stitch_gauge_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of stitches per 10 cm"])},
    "Row_gauge_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of rows per 10 cm"])},
    "Width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Width"])},
    "Width_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type in the desired width"])},
    "Width_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The desired width of the piece (cm)"])},
    "Length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Length"])},
    "Length_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type in the desired length"])},
    "Length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The desired length of the piece (cm)"])},
    "Project_dimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your project"])},
    "Project_dimensions_information_bubble_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The knitting gauge consists of stitch gauge and row gauge. Stitch gauge determines the number of stitches required for a 10 cm width, while row gauge determines the number of rows needed for a 10 cm length."])},
    "Project_dimensions_information_bubble_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of stitches or rows is calculated based on your knitting gauge, combined with the desired width or length you intend to knit."])},
    "Pattern_repetition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pattern"])},
    "Pattern_repetition_information_bubble_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use this section if you would like to add a pattern to your knitted piece."])},
    "Pattern_repetition_information_bubble_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra stitches are included in the calculations but are not repeated, e.g. stitches to complete the pattern or edge stitches. Example: if each side of the piece requires 5 edge stitches, and 1 stitch is required to complete the pattern, then the number of extra stitches is 5 + 5 + 1 = 11."])},
    "Pattern_repetition_information_bubble_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra stitches are generally used when knitting back and forth, but are most often 0 when knitting in the round."])},
    "Pattern_stitch_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stitch count"])},
    "Pattern_stitch_count_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type in the pattern stitch count"])},
    "Pattern_stitch_count_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of stitches required for each pattern repetition"])},
    "Additional_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra stitches"])},
    "Additional_stitches_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type in the number of additional stitches"])},
    "Additional_stitches_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total number of additional stitches required to finish the pattern"])},
    "Pattern_row_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Row count"])},
    "Pattern_row_count_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type in the pattern row count"])},
    "Pattern_row_count_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of rows required for each pattern repetition"])},
    "Additional_rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra rows"])},
    "Additional_rows_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type in the number of additional rows"])},
    "Additional_rows_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total number of additional rows required to finish the pattern"])},
    "Stitches_equals": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("stitchCount")), " stitches equals ", _interpolate(_named("width")), " cm."])},
    "Rows_equal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("rowCount")), " rows equals ", _interpolate(_named("length")), " cm."])},
    "Stitches_equals_with_pattern": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("stitchCount")), " stitches equals ", _interpolate(_named("width")), " cm (", _interpolate(_named("widthDifference")), " cm). This entails ", _interpolate(_named("patternRepetition")), " pattern repetitions and ", _interpolate(_named("patternAdditionalCount")), " additional stitch(es)."])},
    "Rows_equals_with_pattern": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("rowCount")), " rows equals ", _interpolate(_named("length")), " cm (", _interpolate(_named("lengthDifference")), " cm). This entails ", _interpolate(_named("patternRepetition")), " pattern repetitions and ", _interpolate(_named("patternAdditionalCount")), " additional row(s)."])}
  },
  "gauge_converter": {
    "tool_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This tool converts stitch or row counts to achieve a consistent width or length using a new knitting gauge. It can be helpful when changing yarn in a pattern."])},
    "Current_information_bubble_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This section applies to your current knitting gauge, the one you are changing to and plan to use for your project."])},
    "Original_information_bubble_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This section applies to the original knitting gauge, the one you are changing from. This can e.g. be a knitting gauge from a pattern, and an accompanying number of stitches or rows."])},
    "Current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your project"])},
    "Original": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original"])},
    "Number_of_stitches_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of stitches you wish to convert"])},
    "Number_of_rows_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of rows you wish to convert"])},
    "Stitch_gauge_current_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The current number of stitches per 10 cm"])},
    "Row_gauge_current_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The current number of rows per 10 cm"])},
    "Stitch_gauge_original_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The original number of stitches per 10 cm"])},
    "Row_gauge_original_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The original number of rows per 10 cm"])},
    "Original_stitch_count_equals_cm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The wanted width is ", _interpolate(_named("width")), " cm."])},
    "Original_row_count_equals_cm": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The wanted length is ", _interpolate(_named("length")), " cm."])},
    "Stitches_equals": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This equals ", _interpolate(_named("stitchCount")), " stitches with your current stitch gauge."])},
    "Rows_equal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["This equals ", _interpolate(_named("rowCount")), " rows with your current row gauge."])}
  },
  "common_tools": {
    "Calculate_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculate for"])},
    "Stitch_gauge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stitch gauge"])},
    "Stitch_gauge_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type in the number of stitches per 10 cm"])},
    "Row_gauge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Row gauge"])},
    "Row_gauge_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type in the number of rows per 10 cm"])},
    "Number_of_stitches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stitch count"])},
    "Number_of_rows": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Row count"])},
    "Number_of_stitches_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type in the number of stitches"])},
    "Number_of_rows_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type in the number of rows"])}
  },
  "forgot_password": {
    "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "New_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
    "A_new_password_has_successfully_been_requested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success! Click the link in the email we just sent you to reset your password."])},
    "Password_successfully_reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password has successfully been reset."])}
  }
}
<template>
  <div v-if="loading" class="app-loading-overlay">
    <i class="mdi mdi-loading mdi-spin"></i>
  </div>
  <h3 class="app-page-title">{{ $t("common.Socks") }}</h3>
  <v-container class="app-pattern-form-container" fluid>
    <v-row justify="center">
      <v-col cols="12" sm="12" md="6" class="app-pattern-form-col">
        <v-form class="app-pattern-form" @submit.prevent="calculatePattern(false)">
          <div class="app-pattern-form-section">
            <header class="app-pattern-form-header">
              <div>
                {{ $t("common.Measurements") }}
              </div>
            </header>
            <persons-select-field
              v-model="formData.chosenPerson"
              @item-selected="(item) => onPersonSelected(formData, item.value)"
            >
            </persons-select-field>
            <select-field
              v-if="formData.chosenPerson != null && formData.chosenPerson.value != 'custom'"
              v-model="formData.chosenSize"
              ref="selectSizesField"
              :label="$t('socks.Shoe_size')"
              :placeholder="$t('socks.Choose_a_shoe_size')"
              :items="sizes"
              :disabled="sizeSelectorDisabled"
              :small="true"
              @item-selected="(item) => onSizeSelected(formData, item.value)"
            ></select-field>

            <div class="app-pattern-form-side-by-side-container" v-if="formData.chosenSize != null">
              <input-field
                class="app-pattern-form-input-field"
                v-model="formData.ankleCircumf"
                :label="$t('socks.Ankle_circumference')"
                :description="$t('socks.Ankle_circumference_description')"
                :placeholder="$t('socks.Type_in_ankle_circumference')"
                :disabled="valuesDisabled"
                :small="true"
              ></input-field>
              <v-spacer></v-spacer>
              <input-field
                class="app-pattern-form-input-field"
                v-model="formData.footCircumf"
                :label="$t('socks.Foot_circumference')"
                :description="$t('socks.Foot_circumference_description')"
                :placeholder="$t('socks.Type_in_foot_circumference')"
                :disabled="valuesDisabled"
                :small="true"
              ></input-field>
            </div>
            <div class="app-pattern-form-side-by-side-container" v-if="formData.chosenSize != null">
              <input-field
                class="app-pattern-form-input-field"
                v-model="formData.sockHeight"
                :label="$t('socks.Sock_height')"
                :description="$t('socks.Sock_height_description')"
                :placeholder="$t('socks.Type_in_sock_height')"
                :disabled="valuesDisabled"
                :small="true"
              ></input-field>
              <v-spacer></v-spacer>
              <input-field
                class="app-pattern-form-input-field"
                v-model="formData.footLength"
                :label="$t('socks.Sock_length')"
                :description="$t('socks.Sock_length_description')"
                :placeholder="$t('socks.Type_in_sock_length')"
                :disabled="valuesDisabled"
                :small="true"
              ></input-field>
            </div>
          </div>

          <div class="app-pattern-form-section" v-if="formData.chosenSize != null">
            <knitting-gauge
              v-model:row-gauge="formData.rowGauge"
              v-model:stitch-gauge="formData.stitchGauge"
              v-model:estimate-row-gauge-enabled="formData.estimateRowGaugeEnabled"
            ></knitting-gauge>
          </div>
          <div v-if="isSubscribedOrOnTrial">
            <div v-if="newVersionAvailable">
              <div class="new-version-available-notification">
                {{ $t("pattern_form.A_new_version_is_available_") }}
              </div>
              <pattern-form-button
                :label="$t('pattern_form.Upgrade_and_calculate')"
                :disabled="!buttonIsEnabled"
                @click="calculatePattern(true)"
              ></pattern-form-button>
            </div>
            <pattern-form-button
              :label="$t('common.Calculate_pattern')"
              :disabled="!buttonIsEnabled"
            >
            </pattern-form-button>
          </div>
        </v-form>
        <pattern-form-button
          v-if="!isSubscribedOrOnTrial"
          :label="$t('common7.Subscribe')"
          @click="goToSubscription"
        >
        </pattern-form-button>
      </v-col>
      <v-col cols="12" sm="12" md="6" v-if="showPattern">
        <pattern-renderer
          :current-version="currentVersion"
          :versions="versions"
          :disabled="buttonIsEnabled"
          v-model:bookmark="bookmark"
          :data="sockData"
        ></pattern-renderer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref, onMounted, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import InputField from "../../components/general/InputField.vue";
import KnittingGauge from "../../components/KnittingGauge.vue";
import { composeRoute } from "../../lib/navigationUtils";
import PatternFormButton from "../../components/patterns/PatternFormButton.vue";
import PatternRenderer from "./../../components/patterns/PatternRenderer";
import PersonsSelectField from "./../../components/patterns/PersonsSelectField";
import { calculateSocks } from "./../../services/pattern";
import SelectField from "../../components/general/SelectField.vue";
import {
  sizesSelectFieldDisabled,
  measurementFieldsDisabled,
  hasEmptyValues,
} from "../../lib/patternFormUtils";
import { loadCurrentProject, saveCurrentProject } from "../../services/project";
import { useLangStore } from "./../../stores/lang";

const loading = ref(false);

const langStore = useLangStore();
const locale = langStore.getLocale();
const router = useRouter();

const patternType = "sock";
const bookmark = ref(null);

const latestVersion = "1.1.0";
const currentVersion = ref(latestVersion);

const versions = {
  "1.0.0": "SockPattern",
  "1.1.0": "SockPattern_v1_1_0",
};

const { t } = useI18n();

const formData = ref({
  chosenPerson: null,
  chosenSize: null,
  stitchGauge: null,
  rowGauge: null,
  estimateRowGaugeEnabled: true,
  ankleCircumf: null,
  footCircumf: null,
  sockHeight: null,
  footLength: null,
});

const selectSizesField = ref(null);
const sizes = ref(null);
let sockData = ref(null);

const sizeSelectorDisabled = computed(() => sizesSelectFieldDisabled(formData.value));
const valuesDisabled = computed(() => measurementFieldsDisabled(formData.value));

const formHasChanges = ref(false);
const showPattern = ref(false);

const isSubscribedOrOnTrial = ref(true);

watch(
  () => formData.value.chosenPerson,
  () => {
    if (selectSizesField.value) {
      selectSizesField.value.resetSelection();
    }
    switch (formData.value.chosenPerson.value) {
      case "child":
        sizes.value = sizeBabyChildYouth.value;
        break;
      case "grown_up":
        sizes.value = sizeGrownUp.value;
        break;
      case "custom":
        sizes.value = null;
    }
  }
);

function onPersonSelected(formData, newChosenPerson) {
  if (newChosenPerson == "custom") {
    formData.chosenSize = "custom";
  } else {
    formData.chosenSize = null;
  }
  formData.ankleCircumf = null;
  formData.footCircumf = null;
  formData.sockHeight = null;
  formData.footLength = null;
}

function onSizeSelected(formData, newChosenSize) {
  if (newChosenSize != null) {
    formData.ankleCircumf = newChosenSize.sizeAnkleCircumf;
    formData.footCircumf = newChosenSize.sizeFootCircumf;
    formData.sockHeight = newChosenSize.sizeSockHeight;
    formData.footLength = newChosenSize.sizeTotalFootLength;
  }
}

onMounted(async () => {
  const project = await loadCurrentProject(patternType);
  if (project != null) {
    formData.value = project.settings;
    bookmark.value = project.bookmark;
    currentVersion.value = project.patternVersion;
    calculatePattern(false);
  }
});

const buttonIsEnabled = computed(() => {
  return formIsValid.value && formHasChanges.value;
});

const formIsValid = computed(() => {
  return !hasEmptyValues(formData.value);
});

const newVersionAvailable = computed(() => {
  return currentVersion.value != latestVersion;
});

const calculatePattern = async (upgrade) => {
  try {
    loading.value = true;
    if (upgrade) {
      currentVersion.value = latestVersion;
    }
    if (formIsValid.value) {
      await getSockParameters();
      formHasChanges.value = false;
      await saveCurrentProject(patternType, currentVersion.value, formData.value, bookmark.value);
    }
  } catch (error) {
    if (error.response && error.response.status === 403) {
      isSubscribedOrOnTrial.value = false;
    } else {
      throw error;
    }
  } finally {
    loading.value = false;
  }
};

async function getSockParameters() {
  const sockParameters = await calculateSocks(
    formData.value.stitchGauge / 10,
    formData.value.rowGauge / 10,
    formData.value.ankleCircumf,
    formData.value.sockHeight,
    formData.value.footCircumf,
    formData.value.footLength
  );

  showPattern.value = true;
  sockData.value = sockParameters;
  return sockParameters;
}

const goToSubscription = async () => {
  await saveCurrentProject(patternType, currentVersion.value, formData.value, bookmark.value);
  router.push(composeRoute(locale.value, "subscription", "socks"));
};

watch(
  formData,
  () => {
    formHasChanges.value = true;
  },
  { deep: true }
);

watch(bookmark, async () => {
  await saveCurrentProject(patternType, currentVersion.value, formData.value, bookmark.value);
});

const sizeBabyChildYouth = computed(() => {
  return [
    {
      key: "16-17 (0-3 " + t("common.months") + ")",
      value: {
        sizeAnkleCircumf: 12.0,
        sizeFootCircumf: 11.5,
        sizeSockHeight: 10.0,
        sizeTotalFootLength: 9.5,
      },
    },
    {
      key: "17-18 (3-6 " + t("common.months") + ")",
      value: {
        sizeAnkleCircumf: 13.5,
        sizeFootCircumf: 13.0,
        sizeSockHeight: 10.5,
        sizeTotalFootLength: 10.5,
      },
    },
    {
      key: "18-19 (6-9 " + t("common.months") + ")",
      value: {
        sizeAnkleCircumf: 14.5,
        sizeFootCircumf: 14.0,
        sizeSockHeight: 11.0,
        sizeTotalFootLength: 11.0,
      },
    },
    {
      key: "19-20 (9-12 " + t("common.months") + ")",
      value: {
        sizeAnkleCircumf: 15.0,
        sizeFootCircumf: 14.5,
        sizeSockHeight: 11.5,
        sizeTotalFootLength: 12.0,
      },
    },
    {
      key: "20-21 (12-18 " + t("common.months") + ")",
      value: {
        sizeAnkleCircumf: 16.0,
        sizeFootCircumf: 15.0,
        sizeSockHeight: 12.0,
        sizeTotalFootLength: 13.0,
      },
    },
    {
      key: "22-23 (2 " + t("common.years") + ")",
      value: {
        sizeAnkleCircumf: 16.5,
        sizeFootCircumf: 15.5,
        sizeSockHeight: 12.5,
        sizeTotalFootLength: 14.0,
      },
    },
    {
      key: "24-25 (3 " + t("common.years") + ")",
      value: {
        sizeAnkleCircumf: 17.0,
        sizeFootCircumf: 17.0,
        sizeSockHeight: 13.0,
        sizeTotalFootLength: 15.0,
      },
    },
    {
      key: "26-27 (4 " + t("common.years") + ")",
      value: {
        sizeAnkleCircumf: 18,
        sizeFootCircumf: 18,
        sizeSockHeight: 14,
        sizeTotalFootLength: 16.5,
      },
    },
    {
      key: "28-29 (5 " + t("common.years") + ")",
      value: {
        sizeAnkleCircumf: 19.0,
        sizeFootCircumf: 19.0,
        sizeSockHeight: 15.0,
        sizeTotalFootLength: 18.0,
      },
    },
    {
      key: "30-31 (6 " + t("common.years") + ")",
      value: {
        sizeAnkleCircumf: 19.5,
        sizeFootCircumf: 19.5,
        sizeSockHeight: 16.5,
        sizeTotalFootLength: 19.0,
      },
    },
    {
      key: "32-34 (7 " + t("common.years") + ")",
      value: {
        sizeAnkleCircumf: 20.5,
        sizeFootCircumf: 20.5,
        sizeSockHeight: 17.5,
        sizeTotalFootLength: 21.0,
      },
    },
    {
      key: "35-36 (8 " + t("common.years") + ")",
      value: {
        sizeAnkleCircumf: 21.5,
        sizeFootCircumf: 21.5,
        sizeSockHeight: 18.5,
        sizeTotalFootLength: 22.5,
      },
    },
    {
      key: "37-38 (9-10 " + t("common.years") + ")",
      value: {
        sizeAnkleCircumf: 23.0,
        sizeFootCircumf: 23.0,
        sizeSockHeight: 19.5,
        sizeTotalFootLength: 24.0,
      },
    },
  ];
});
const sizeGrownUp = computed(() => {
  return [
    {
      key: "34-35",
      value: {
        sizeAnkleCircumf: 20.0,
        sizeFootCircumf: 20.0,
        sizeSockHeight: 19.0,
        sizeTotalFootLength: 22.5,
      },
    },
    {
      key: "36-37",
      value: {
        sizeAnkleCircumf: 21.5,
        sizeFootCircumf: 21.5,
        sizeSockHeight: 19.5,
        sizeTotalFootLength: 23.5,
      },
    },
    {
      key: "38-39",
      value: {
        sizeAnkleCircumf: 23.0,
        sizeFootCircumf: 23.0,
        sizeSockHeight: 20,
        sizeTotalFootLength: 24.5,
      },
    },
    {
      key: "40-41",
      value: {
        sizeAnkleCircumf: 24.0,
        sizeFootCircumf: 24.0,
        sizeSockHeight: 21,
        sizeTotalFootLength: 25.5,
      },
    },
    {
      key: "42-43",
      value: {
        sizeAnkleCircumf: 25.0,
        sizeFootCircumf: 25.0,
        sizeSockHeight: 22,
        sizeTotalFootLength: 27.0,
      },
    },
    {
      key: "44-45",
      value: {
        sizeAnkleCircumf: 26.5,
        sizeFootCircumf: 26.5,
        sizeSockHeight: 23.0,
        sizeTotalFootLength: 27.5,
      },
    },
    {
      key: "46-47",
      value: {
        sizeAnkleCircumf: 28.0,
        sizeFootCircumf: 28.0,
        sizeSockHeight: 24.0,
        sizeTotalFootLength: 29.0,
      },
    },
    {
      key: "48",
      value: {
        sizeAnkleCircumf: 28.5,
        sizeFootCircumf: 28.5,
        sizeSockHeight: 24.5,
        sizeTotalFootLength: 30.0,
      },
    },
  ];
});
</script>

<style lang="scss" scoped>
@import "./../../../scss/form.scss";
</style>

<template>
  <v-menu open-on-hover open-delay="0">
    <template v-slot:activator="{ props }">
      <v-btn class="app-btn-icon app-navbar-icon-button" v-bind="props">
        <v-icon icon="mdi-web" />
        {{ langText }}
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        v-for="(value, option) in langOptions"
        :key="option"
        :value="value"
        :active="locale == option"
        @click="setLocale(option)"
      >
        <v-list-item-title>
          {{ value }}
          <div class="beta-badge-container" v-if="value == 'DA'"><beta-badge></beta-badge></div>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup>
import { computed } from "vue";
import { useRouter, useRoute } from "vue-router";

import { useLangStore } from "./../stores/lang";
import { composeRoute } from "./../lib/navigationUtils.js";
import BetaBadge from "./BetaBadge.vue";

const router = useRouter();
const route = useRoute();

const langStore = useLangStore();

const langOptions = langStore.getSupportedLocales;

const locale = langStore.getLocale();

const langText = computed(() => {
  return langOptions[locale.value];
});

const setLocale = (value) => {
  langStore.setLocale(value);
  router.push(composeRoute(value, route.name));
};
</script>

<style scoped>
.beta-badge-container {
  position: fixed;
  top: -2px;
  left: 35px;
}
</style>

import axios from "../axios";

import { useAuthStore } from "../stores/auth";

export const calculateSocks = async (
  stitchGauge,
  rowGauge,
  ankleCircumference,
  sockHeight,
  footCircumference,
  footLength
) => {
  const authStore = useAuthStore();
  const authToken = authStore.authToken;

  const params = {
    stitch_gauge: stitchGauge,
    row_gauge: rowGauge,
    ankle_circumference: ankleCircumference,
    sock_height: sockHeight,
    foot_circumference: footCircumference,
    foot_length: footLength,
  };

  const response = await axios.get("/patterns/sock", {
    params: params,
    headers: { Authorization: `Bearer ${authToken}` },
  });
  const returnObject = {
    stitchGauge: response.data.stitch_gauge,
    rowGauge: response.data.row_gauge,
    ankleCircumference: response.data.ankle_circumference,
    sockHeight: response.data.sock_height,
    footCircumference: response.data.foot_circumference,
    footLength: response.data.foot_length,
    castOnStitchCount: response.data.cast_on_stitch_count,
    footStitchCount: response.data.foot_stitch_count,
    heelFlapEdgeStitchCount: response.data.heel_flap_edge_stitch_count,
    heelFlapRowCount: response.data.heel_flap_row_count,
    cuffLength: response.data.cuff_length,
    anklePieceLength: response.data.ankle_piece_length,
    heelFlapMarginStitchCount: response.data.heel_flap_margin_stitch_count,
    tipLength: response.data.tip_length,
    tipMarginStitchCount: response.data.tip_margin_stitch_count,
    requiresSimplifiedDecrease: response.data.requires_simplified_decrease,
    finalRowStitchCount: response.data.final_row_stitch_count,
    heelFlapCenterStitchCount: response.data.heel_flap_center_stitch_count
  };

  return returnObject;
};

export const calculateMittens = async (
  stitchGauge,
  rowGauge,
  wristCircumf,
  cuffLength,
  handCircumf,
  handLength,
  feltRatio,
  patternVersion
) => {
  const authStore = useAuthStore();
  const authToken = authStore.authToken;

  const params = {
    stitch_gauge: stitchGauge,
    row_gauge: rowGauge,
    wrist_circumference: wristCircumf,
    cuff_length: cuffLength,
    hand_circumference: handCircumf,
    hand_length: handLength,
    felt_ratio: feltRatio,
    version: patternVersion
  };

  const response = await axios.get("/patterns/mitten", {
    params: params,
    headers: { Authorization: `Bearer ${authToken}` },
  });
  const returnObject = {
    stitchGauge: response.data.stitch_gauge,
    rowGauge: response.data.row_gauge,
    cuffLength: response.data.cuff_length,
    wristCircumference: response.data.wrist_circumference,
    handCircumference: response.data.hand_circumference,
    handLength: response.data.hand_length,
    feltRatio: response.data.felt_ratio,
    calculatedCuffLength: response.data.calculated_cuff_length,
    calculatedWristCircumf: response.data.calculated_wrist_circumf,
    castOnStitchCount: response.data.cast_on_stitch_count,
    calculatedHandCircumf: response.data.calculated_hand_circumf,
    handStitchCount: response.data.hand_stitch_count,
    calculatedHandLength: response.data.calculated_hand_length,
    beforeThumbLength: response.data.before_thumb_length,
    thumbMarginStitchCount: response.data.thumb_margin_stitch_count,
    thumbOpeningStitchCount: response.data.thumb_opening_stitch_count,
    tipMarginStitchCount: response.data.tip_margin_stitch_count,
    finalRowStitchCount: response.data.final_row_stitch_count,
    requiresSimplifiedDecrease: response.data.requires_simplified_decrease,
    skipStitchDecreaseRowCount: response.data.skip_stitch_decrease_row_count,
    tipLength: response.data.tip_length,
    thumbLength: response.data.thumb_length,
    initialThumbStitchCount: response.data.initial_thumb_stitch_count,
    stitchesKnitTogetherInSecondRow: response.data.stitches_knit_together_in_second_row,
    thumbStitchCount: response.data.thumb_stitch_count,
    thumbTipRowCount: response.data.thumb_tip_row_count,
    thumbTipLength: response.data.thumb_tip_length,
    thumbFinalRowStitchCount: response.data.thumb_final_row_stitch_count,
  };
  
  return returnObject;
};

export const calculateBeanie = async (
  stitchGauge,
  rowGauge,
  headCircumf,
  foldLength,
  cuffPatternRepCount,
  stockinetteTop,
  decreaseType
) => {
  const authStore = useAuthStore();
  const authToken = authStore.authToken;

  const params = {
    stitch_gauge: stitchGauge,
    row_gauge: rowGauge,
    head_circumference: headCircumf,
    fold_length: foldLength,
    cuff_pattern_rep_count: cuffPatternRepCount,
    stockinette_top: stockinetteTop,
    decrease_type: decreaseType,
  };

  const response = await axios.get("/patterns/beanie", {
    params: params,
    headers: { Authorization: `Bearer ${authToken}` },
  });
  const returnObject = {
    stitchGauge: response.data.stitch_gauge,
    rowGauge: response.data.row_gauge,
    headCircumf: response.data.head_circumference,
    foldLength: response.data.fold_length,
    decreaseType: response.data.decrease_type,
    cuffPatternRepCount: response.data.cuff_pattern_rep_count,
    stockinetteTop: response.data.stockinette_top,
    calculatedHeadCircumf: response.data.calculated_head_circumf,
    castOnStitchCount: response.data.cast_on_stitch_count,
    topPatternRepCount: response.data.top_pattern_rep_count,
    extraRoundedDecrease_needed: response.data.extra_rounded_decrease_needed,
    roundingRowCount: response.data.rounding_row_count,
    decreaseLineStitchCount: response.data.decrease_line_stitch_count,
    evenlyDecreasedStitchesCount: response.data.evenly_decreased_stitches_count,
    crossDecreaseCount: response.data.cross_decrease_count,
    crossDecreaseArray: response.data.cross_decrease_array,
    everyOtherRowDecreaseCount: response.data.every_other_row_decrease_count,
    everyRowDecreaseCount: response.data.every_row_decrease_count,
    evenDecreaseArray: response.data.even_decrease_array,
    lengthOfDecrease: response.data.length_of_decrease,
    lengthBeforeDecrease: response.data.length_before_decrease,
    straightCuffLength: response.data.straight_cuff_length,
    crossDecreaseSegmentsArray: response.data.cross_decrease_segments_array,
    crossDecreaseFinalRowStitchCount: response.data.cross_decrease_final_row_stitch_count,
    crossDecreaseIsEven: response.data.cross_decrease_is_even
  };

  return returnObject;
};
